<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header>
          <span slot="r"
            ><slot><a href="/userList">用户管理 — </a></slot></span
          >
          <span slot="m"
            ><slot><a href="/userList">用户列表 </a></slot></span
          >
          <span slot="l"><slot>> 修改基本信息 </slot></span>
        </base-page-header>
        <div class="main-manager">
          <el-form
            :model="userForm"
            :rules="rules"
            ref="userForm"
            label-width="130px"
            class="userForm"
          >
            <el-form-item label="序号：">
              <p>{{ userForm.userid }}</p>
            </el-form-item>
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="userForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
              <el-select v-model="userForm.sex" placeholder="请选择">
                <el-option label="男" :value="0"></el-option>
                <el-option label="女" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号：" prop="phone">
              <el-input
                v-model="userForm.phone"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="工号：" prop="workid">
              <el-input
                v-model="userForm.workid"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否被禁用：">
              <el-checkbox v-model="userForm.is_forbidden"></el-checkbox>
            </el-form-item>
            <el-form-item label="微信openid：">
              <p>{{ userForm.wxopenid }}</p>
            </el-form-item>
            <el-form-item label="创建时间：">
              <p>{{ userForm.date }}</p>
            </el-form-item>
            <el-form-item>
              <el-button class="reset" @click="resetForm">重置</el-button>
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('userForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import UserJS from 'network/user/user.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 0,
      params: {},
      userForm: {
        name: '',
        sex: '',
        phone: '',
        workid: '',
        is_forbidden: false
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名名称', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
          // {
          //   pattern: /^1[34578]\d{9}$/,
          //   message: '目前只支持中国大陆的手机号码'
          // }
        ],
        workid: [{ required: true, message: '请输入工号', trigger: 'blur' }]
      }
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 修改并提交用户表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        this.userUpdate()
      })
    },
    /**
     * 网络请求
     */
    userUpdate() {
      this.userForm.session_id = this.params.session_id
      UserJS.userUpdate(this.userForm).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.$router.replace('/userList')
          }, 500)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    userGet() {
      this.params.session_id = this.$store.state.loginInfo.session_id
      this.params.userid = this.$route.query.userid
      UserJS.userGet(this.params).then((res) => {
        if (res.code == 0) {
          this.userForm = res.user_data
          this.userForm.sex = res.user_data.sex == '男' ? 0 : 1
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    resetForm() {
      this.userGet()
    }
  },
  mounted() {
    this.userGet()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    width: 1642px;
    height: 82.5vh;
    background: #fff;
    .userForm {
      padding: 85px 0 0 72px;
      width: auto;
      height: auto;
      .reset {
        margin-left: 90px;
        border-radius: 0;
      }
      .submit {
        margin-left: 20px;
        border-radius: 0;
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 0px;
    }
    .el-form-item:not(:last-child) {
      margin-bottom: 28px;
      border-radius: 0px;
      .el-form-item__label {
        font-size: 18px;
        font-family: Source Han Sans CN;
      }
      .el-input {
        width: 332px;
        height: 42px;
      }
    }
  }
}
</style>
